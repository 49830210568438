export function initializeSlider() {
    // Head-Slider
    var swiper = new Swiper(".vs-slider-left", {
        autoHeight: true,
        loop: true,
        effect: "fade",
        autoplay: {
            delay: 5000,
        },
    });
    var swiper = new Swiper(".vs-slider-right", {
       autoplay: true,
        autoHeight: true,
        loop: true,
        effect: "fade",
    });

}


